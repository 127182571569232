import React, { Component, useRef, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Img1 from "../../images/pageImages/Homepage/student.jpg";
import Img2 from "../../images/pageImages/Homepage/Mission.jpeg";
import Img3 from "../../images/pageImages/Homepage/Carousel2.jpg";
import Img4 from "../../images/pageImages/Homepage/Carousel3.jpg";
import rightArrow from "../../images/carousel-arrow.svg";
import leftArrow from "../../images/carousel-left-arrow.svg";
import ReactGA from "react-ga";

const images = [
  {
    image: `${Img1}`,
    name: "Victoria Ayodeji",
    description: " Winner of the 2019 Outstanding Achievement Award",
  },
  {
    image: `${Img2}`,
    name: "Oluwaseye Ibare-Akinsan",
    description: " Winner of Best Mentor 2019",
  },
  {
    image: `${Img3}`,
    name: "Philippa Ancla Uden",
    description: "Winner of Creative Industries Award 2019",
  },
  {
    image: `${Img4}`,
    name: "test",
    description: "asdsadas",
  },
];

const StyledWrapper = styled.div`
  .slider-slick {
    max-width: ${props => props.theme["percent-90"]};
    margin: auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
    border: 0.25px solid rgba(151, 151, 151, 0.5);
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .img-class {
    height: 469px;
    width: 100%;
    margin-bottom: 0px;
    object-fit: cover;
    object-position: top;
    @media (max-width: 991px) {
      width: 100%;
      height: 500px;
    }

    @media (max-width: 600px) {
      height: 250px;
    }

    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .slick-prev {
    left: -9.7%;
    z-index: 1;
    background-color: black;
  }

  .slick-next {
    right: -9.7%;
    z-index: 1;
    background-color: black;
  }

  .slick-next:hover,
  .slick-prev:hover {
    background-color: black;
  }

  .slick-prev:before {
    content: "";
  }

  .slick-next:before {
    content: "";
  }

  .custom-arrow {
    width: 17px;
    height: 29px;
    margin-bottom: 0px;
  }

  .item-name {
    color: white;
    font-size: 24px;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .item-description {
    color: white;
    font-size: 17px;
    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  .overlay {
    margin-top: -135px;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    height: 135px;
    left: 2%;
    width: 95.7%;
    padding-top: 15px;

    @media (max-width: 600px) {
      left: 4%;
      width: 92%;
      margin-top: -110px;
      height: 110px;
      padding-top: 0px;
    }

    @media (min-width: 601px) and (max-width: 768px) {
      left: 1.5%;
      width: 97%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      left: 2.5%;
      width: 95%;
      margin-top: -150px;
      height: 150px;
    }
  }
`;

function NextArrow(props) {
  const { className, style, onClick } = props;
  const right = useRef(null);
  useEffect(() => {
    right.current.onclick = function (event) {
      ReactGA.event({
        category: "Homepage",
        action: "Carousel Right Button Click",
      });
    };
  }, [NextArrow]);
  return (
    <div
      className={className}
      ref={right}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img src={rightArrow} className="custom-arrow" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  const left = useRef(null);
  useEffect(() => {
    left.current.onclick = function (event) {
      ReactGA.event({
        category: "Homepage",
        action: "Carousel Left Button Click",
      });
    };
  }, [PrevArrow]);
  return (
    <div
      className={className}
      ref={left}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img src={leftArrow} className="custom-arrow" />
    </div>
  );
}
export default class ImageCarousel extends Component {
  render() {
    var settings = {
      speed: 500,
      slidesToShow: 4,
      infinity: true,
      slidesToScroll: 1,
      arrows: true,
      accessibilty: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            // dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            infinity: true,
            slidesToScroll: 3,
            arrows: true,
            accessibilty: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            // dots: true,
            arrows: false,
            centerMode: true,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            dots: false,
            arrows: true,
            centerMode: true,
            adaptiveHeight: true,
          },
        },
      ],
    };
    const mainUrl = process.env.GATSBY_API_URL;
    return (
      <StyledWrapper className="padding-left-8 padding-right-8 container-fluid">
        <Slider {...settings}>
          {this.props.data.map(item => (
            <div>
              {item.Image && (
                <img
                  src={mainUrl + item.Image.url}
                  className="img-class img-fluid"
                  alt=""
                />
              )}
              <div className="overlay">
                <div className="text px-2 pt-2">
                  <span className="item-name">{item.name}</span>
                  <p className="item-description">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </StyledWrapper>
    );
  }
}
